<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				About Bridge128
			</div>
			<div class="page__info--subtitle">
				Learn how how our e-learning platform works and start improving your game today.
			</div>
		</div>
		<div class="separator" />
		<div class="about content">
			<div class="card-wrapper">
				<v2-about-card v-for="card in categories" :key="card.id" :card="card" />
			</div>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>
	import V2AboutCard from '@/components/v2/about/V2AboutCard.vue';
	import api                from '@/services/api';

	export default {
		layout: 'v2default',

		components: {
			V2AboutCard
		},

		data () {
			return {
				categories: []
			};
		},

		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/about',
						text: 'About'
					}
				].filter(Boolean);
			}
		},

		created () {
			this.setCategories();
		},

		methods: {
			async setCategories () {
				const categories = await api.knowledgebase.getCategories({
					include: 'featured'
				});
				if (!categories) {
					this.$store.commit('ui/showError');
					return false;
				}

				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});

				this.categories = categories.sort((a, b) => a.id - b.id);
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}

	};
</script>

<style lang="scss" scoped>

.about {
  .card-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;

    @media (max-width: 1279px) {
      grid-template-columns: 1fr;
    }
  }
}

</style>

<template>
	<div class="about-card">
		<div class="about-card__body">
			<div class="about-card__body--icon">
				<icon :icon="categoryIcon" colour="transparent" />
			</div>
			<div class="about-card__body--title">
				{{ card.title }}
			</div>
			<router-link
				v-for="link in card.featured"
				:key="link.id"
				:to="`/about/${card.slug}/${link.slug}`"
				class="about-card__body--link"
			>
				{{ link.title }}
			</router-link>
		</div>
		<div class="about-card__footer">
			<router-link class="about-card__footer--more" :to="`/about/${card.slug}`">
				More Articles
				<icon icon="v2-chevron-left" colour="transparent" />
			</router-link>
		</div>
	</div>
</template>

<script>
	import Icon             from '@/components/ui/Icon';
	export default {
		components: {
			Icon
		},

		props: {
			card: {
				type: Object,
				required: true
			}
		},

		computed: {
			categoryIcon () {

        if (this.card.slug === 'learning') {
          return 'v2-about-learning';
        }

        if (this.card.slug === 'terms-and-conditions') {
          return 'v2-about-terms';
        }

        if (this.card.slug === 'teaching') {
          return 'v2-about-teaching';
        }

        if (this.card.slug === 'playing') {
          return 'v2-about-playing';
        }

				return 'v2-about-info';
			}
		}

	};
</script>

<style lang="scss" scoped>
.about-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid $c-gray-l2;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    background: $c-white-l;
    height: 100%;

    &--icon {
      width: 26px;
      height: 26px;
    }

    &--title {
      @include font(20px, $c-dark-navy, 500, 26px);
    }

    &--link {
      @include font(16px, $c-dark-navy, 400, 20px);
      text-decoration: underline
    }
  }

  &__footer {
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    background: $c-gray-l;

    &--more {
      display: flex;
      align-items: center;
      gap: 14px;
      @include font(16px, $c-dark-navy, 400, 20px);

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
